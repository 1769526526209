import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import store from './store'
import { setToken, getToken } from '@/utils/auth'
import './permission'
import './utils/directives.js'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import PdUploadImage from '@/components/Psyduck/Upload/image'
Vue.component('pd-upload-image', PdUploadImage)

import request from '@/utils/request'
Vue.prototype.$request = request
Vue.prototype.$post = (url, data) => request.post(url, data, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  transformRequest: [data => Object.keys(data).map(it => encodeURIComponent(it) + '=' + encodeURIComponent(data[it])).join('&')]
})
Vue.prototype.$get = (url, data) => request({ url, params: data })

Vue.config.productionTip = false

// Extract and store token from URL before creating Vue instance
const urlParams = new URLSearchParams(window.location.search)
const token = urlParams.get('token')
if (token) {
  setToken(token)
} else {
  // Check hash for token (for hash router mode)
  const hashParams = new URLSearchParams(window.location.hash.split('?')[1] || '')
  const hashToken = hashParams.get('token')
  if (hashToken) {
    setToken(hashToken)
  }
}

// Initialize store with token if available
const currentToken = getToken()
if (currentToken) {
  store.commit('user/SET_TOKEN', currentToken)
}

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
