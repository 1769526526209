import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'

// In-memory token fallback for iframe scenarios
let memoryToken = null;

export function getToken() {
  // First try to get from cookies
  const cookieToken = Cookies.get(TokenKey);
  if (cookieToken) {
    return cookieToken;
  }
  
  // If cookies fail (e.g., in cross-domain iframe), use memory storage
  return memoryToken;
}

export function setToken(token) {
  // Store in memory for cross-domain iframe scenarios
  memoryToken = token;
  
  // Also try to store in cookies (may fail in cross-domain context)
  try {
    return Cookies.set(TokenKey, token);
  } catch (e) {
    console.warn('Failed to set cookie, using memory storage instead:', e);
    return token;
  }
}

export function removeToken() {
  // Clear memory token
  memoryToken = null;
  
  // Also clear cookie
  return Cookies.remove(TokenKey);
}
