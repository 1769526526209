import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // Always try to get token, even if not in store
    const token = getToken()
    if (token) {
      // Add token to headers
      config.headers['token'] = token
      // Also add other required headers for CORS
      config.headers['accept'] = 'application/json, text/plain, */*'
      config.headers['cache-control'] = 'no-cache'
      config.headers['pragma'] = 'no-cache'
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 1, it is judged as an error.
     if (res.type == '' && res.code !== 200) {
      var duration = 5
      if (res.code === 402) {
        duration = 1
      }
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: duration * 1000,
        showClose: true,
        onClose: function() {
          if (res.code === 402) {
            store.dispatch('user/logout').then(() => {
              location.reload()
            })
          }
        }
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
